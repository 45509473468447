import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Trabajos = ({ data }) => {
  const Trabajos = data.allContentfulTrabajo.edges;
  
  return (
    <Layout>
      <SEO title="Portfolio Items" />
      <h1>{"Esta es la lista de las creaciones"}</h1>
      <div className="portfolioitems">
        {
          Trabajos.map(({node: item}) => (
            <div key={item.id}>
              <Link to={`/portfolioitem/${item.slug}`}>{item.titulo}</Link>
            </div>
          ))
        }
      </div>
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}

export default Trabajos

export const query = graphql`
  query PortfolioItemsPageQuery {
    allContentfulTrabajo(limit: 1000) {
      edges {
        node {
          descripcion {
            descripcion
          }
          etiquetas
          imagenes {
            file {
              url
            }
          }
          slug
          titulo
        }
      }
    }
  }
`;